// AppContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AppContext = createContext();

const WRHerokuEndpoint = process.env.REACT_APP_WR_HEROKU_ENDPOINT;
const WRDBEndpoint = process.env.REACT_APP_WR_DB_ENDPOINT;

const AppContextProvider = ({ children }) => {
  const [sharedState, setSharedState] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [dataObj, setDataObj] = useState({
    MadLibID: Math.floor(Math.random() * 100000),
    MadLib: null,
    UserSubmission: null,
    MadLibCompleted: null,
    ImagePrompt: null,
    ImageURL_Stability: null,
    ImageURL_Dalle: null,
    ImageURL_MidJourney: null,
  });
  const [isMadLibCompleted, setIsMadLibCompleted] = useState(false);

  const toggleMenu = () => {
    // setIsOpen(!isOpen);
    if (!isOpen) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(() => {
        setIsOpen(true);
      }, 300); 
    } else {
      setIsOpen(false);
    }
  };

  const updateDataObject = (updatedData) => {
    console.log('updateDataObject');
    setDataObj((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };

  useEffect(() => {
    const isDataComplete = dataObj.MadLib && dataObj.UserSubmission && dataObj.MadLibCompleted && dataObj.ImagePrompt && dataObj.ImageURL_Stability && dataObj.ImageURL_Dalle;
    if (isDataComplete) {
      postUpdatedData(dataObj);
    }
  }, [dataObj]);

  const postUpdatedData = async (updatedData) => {
    console.log('postUpdatedData');
    console.log(dataObj);
    const formData = new FormData();
    Object.keys(updatedData).forEach(key => {
      formData.append(key, updatedData[key]);
    });
    try {
      const response = await axios.post(
        `${WRHerokuEndpoint}/${WRDBEndpoint}/post-entries.php`,
        formData,
        {
          headers: {
          'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('Data updated and posted:', response);
      const urlParameterID = response.data.split(':')[1].trim();
      // Update the URL with the madLibId
      window.history.pushState({}, '', `?id=${urlParameterID}`);
      //change the MadLibID so we can submit a new entry
      setDataObj({
        MadLibID: Math.floor(Math.random() * 100000)
      });
    } catch (error) {
      console.error('Error posting updated data:', error);
    }
  };

  const replaceWords = (template, replacements) => {
    let words = Array.isArray(replacements) ? replacements : replacements.split(",");
    let regex = /\[([^\]]+)\|([^\]]+)\]/g;
    return template.replace(regex, function(match, p1, p2, offset, string) {
      let replacement = words.length > 0 ? words.shift() : p2;
      return `<strong>${replacement}</strong>`;
    });
  }

  const convertImageUrlToBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting image to Base64:', error);
      return null;
    }
  };


  return (
    <AppContext.Provider value={{ sharedState, setSharedState, dataObj, setDataObj, updateDataObject, postUpdatedData, replaceWords, isMadLibCompleted, setIsMadLibCompleted, isOpen, setIsOpen, toggleMenu, convertImageUrlToBase64 }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
export { AppContextProvider };